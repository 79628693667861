import { useEffect, useState } from 'react';

export function useProgressiveImage(props: {
  placeholder: string;
  src: string;
}) {
  const { placeholder, src } = props;

  const [image, setImage] = useState(placeholder);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImage(src);
    };
  }, [src]);

  return { image, isLoading: image !== src };
}
